<!-- NOTE Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light d-none">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0 w-100">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/auth-login']" [routerLinkActive]="['active']">Login</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']">HOME</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- NOTE Content -->

<div class="admin_layout_outer">
  <div class="left_nav" *ngIf="isElementVisible">
    <div class="left_nav_header p-2">
      <h5 class="mb-0">Change Request Management</h5> <mat-icon class="me-auto toggle_btn b-block d-lg-none"
        (click)="toggleElementVisibility()">close</mat-icon>
    </div>
    <ul class="left_nav_links list-unstyled mb-0">
      <li class="left_nav_links_itm"><a class="d-flex align-items-center gap-2" [routerLink]="['/home/home']"
          [routerLinkActive]="['active']"><mat-icon>dashboard</mat-icon> Dashboard</a></li>
      <li class="left_nav_links_itm"><a class="d-flex align-items-center gap-2"
          [routerLink]="['/changeRequest/changeRequestList']"
          [routerLinkActive]="['active']"><mat-icon>change_circle</mat-icon> Change Request</a></li>
      <li class="left_nav_links_itm" *ngIf="isTrackPageEnabled"><a class="d-flex align-items-center gap-2"
          [routerLink]="['/changeRequest/changeRequestTrack']"
          [routerLinkActive]="['active']"><mat-icon>analytics</mat-icon> Track</a></li>
    </ul>
  </div>
  
  <div class="content_box w-100">
    <header class="dashboard_header shadow-sm d-flex justify-content-end align-items-center gap-4">
      <mat-icon class="me-auto toggle_btn" (click)="toggleElementVisibility()">menu</mat-icon>
      <div class="user_dropdown_wrapper">
        <ng-container *ngIf='appService.currentUser$ | async as user'>
          <span>Welcome {{user.userName}}</span>
        </ng-container>
        <img [matMenuTriggerFor]="menu" class="avtar_img" src="assets/images/avtar_img.png" alt="">
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="appService.logout()">
            <mat-icon>logout</mat-icon>
            <span>LOGOUT</span>
          </button>
        </mat-menu>
      </div>
    </header>
    <div class="dshboard_content_wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>