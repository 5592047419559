// Angular modules
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { RouterLink } from '@angular/router';
import { RouterLinkActive } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, NgIf } from '@angular/common';
import { AppService } from '../../../services/app.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, MatSidenavModule, MatIcon, MatBadgeModule, MatButtonModule, MatIconModule, MatMenuModule, NgIf, RouterOutlet, CommonModule]
})
export class LayoutHeaderComponent implements OnInit {
  isElementVisible: boolean = true;
  isTrackPageEnabled = false;
  toggleElementVisibility() {
    this.isElementVisible = !this.isElementVisible;
  }

  public isMenuCollapsed: boolean = true;

  constructor
    (
      private router: Router,
      public appService: AppService,
      private jwtHelper: JwtHelperService
    ) {
  }

  public ngOnInit(): void {
    this.checkSuperAdminAccess();
  }

  public async onClickLogout(): Promise<void> {
    this.router.navigate(['/auth/login']);
  }

  public checkSuperAdminAccess() {
    let token = localStorage.getItem("token")
    if (token) {
      let decodedtoken = this.jwtHelper.decodeToken(token);
      if (decodedtoken.roles == "SuperAdmin") {
        this.isTrackPageEnabled = true;
      }
    }
  }
}
